import { isUser, isManager, isAdmin, isDirector } from './model/auth';

export * from './lib/fetcher';
export { downloadFile } from './lib/download-file';
export { $isAuthenticated, $session, logoutRequesting } from './model/auth';
export {
  $allEmployeesList,
  $departmentList,
  $employeeList,
  $employeeByDepartmentList,
  $meetingList,
  $projectList,
  $officeList,
  employeeListFetchRequesting,
  departmentListFetchRequesting,
  meetingListFetchRequesting,
  projectListFetchRequesting,
  officeListFetchRequesting,
} from './model/common-data';
export { $mediaQuery } from './model/media-query';
export { $locale } from './model/i18n';

export {
  Account,
  AccountLoader,
  AccessControl,
  Navigation,
  FileUploader,
  FileUploaderField,
  LocaleSelect,
  Screen,
  SecuredImage,
  Header,
} from './organisms';
export {
  EmployeeOptionLabel,
  PageLoader,
  filterEmployeeOption,
} from './molecules';
export { CommonPageTemplate } from './templates';
export { LoginPage } from './pages/login';
export { ForgotPasswordPage } from './pages/forgot-password';
export { ResetPasswordPage } from './pages/reset-password';
export { NotFoundPage } from './pages/not-found';
export { routes } from './routes';
export { fileIcon, getFileIcon } from './lib/file-icon';
export {
  CommentsList,
  CommentForm,
  deleteCommentRequesting,
  updateCommentRequesting,
  $commentList,
} from './features/comments';
export {
  fetchEmployeeListReport,
  fetchAttendanceListReport,
  fetchEmployeeLeavesReport,
  fetchAttendanceSingleReport,
} from './api';

export const roleCheck = {
  isUser,
  isManager,
  isAdmin,
  isDirector,
};

export * from './constants';

import React from 'react';
import { t, Trans } from '@lingui/macro';
import { useStore } from 'effector-react';
import { combine } from 'effector';

import { BlockHead, SelectBox, Button, SearchField } from '@oca/ui';
import {
  $departmentList,
  AccessControl,
  fetchEmployeeListReport,
} from '@features/common';

import { i18n } from '@lib/i18n';
import { Row, Col } from '@lib/grid';
import { Download } from '@oca/icons';

import {
  changeDepartmentFilter,
  $departmentFilter,
  $statusFilter,
  $search,
  changeEmployeeSearch,
  changeStatusFilter,
} from '../model/list';

const $filters = combine(
  $departmentFilter,
  $search,
  $statusFilter,
  (department, search, statusFilter) => ({
    department,
    search,
    statusFilter,
  }),
);

const $departmentOptions = $departmentList.map(list => [
  { label: <Trans>All</Trans>, id: '' },
  ...list,
]);

const statusOptions = [
  { label: <Trans>All</Trans>, id: 'all' },
  { label: <Trans>Active</Trans>, id: 'active' },
  { label: <Trans>Terminated</Trans>, id: 'terminate' },
];

export function ListHeader() {
  const departments = useStore($departmentOptions);
  const filters = useStore($filters);

  return (
    <BlockHead justifyContent="space-between">
      <Row flex="1 0 auto" alignItems="flex-end" spacing={2} pb={2}>
        <Col width={[1 / 3, 1 / 4, 1 / 6]}>
          <SelectBox
            label={i18n._(t`Department`)}
            options={departments}
            onChange={val => changeDepartmentFilter(val.id)}
            value={
              departments.find(({ id }) => filters.department === id) || null
            }
            menuPortalTarget={document.body}
          />
        </Col>
        <Col width={[1 / 3, 1 / 4, 1 / 6]}>
          <SelectBox
            label={i18n._(t`Status`)}
            options={statusOptions}
            onChange={val => changeStatusFilter(val.id)}
            value={
              statusOptions.find(({ id }) => filters.statusFilter === id) ||
              null
            }
            menuPortalTarget={document.body}
          />
        </Col>
        <Col width="auto" flex="1 0 auto" mt={1}>
          <SearchField
            placeholder={i18n._(t`Type name`)}
            onChange={event => changeEmployeeSearch(event.target.value)}
            value={filters.search}
            fullWidth
          />
        </Col>
        <AccessControl allowed={['admin', 'director', 'manager']}>
          <Col width="auto">
            <Button
              onClick={() =>
                fetchEmployeeListReport({
                  year: new Date().getFullYear(),
                  month: new Date().getMonth() + 1,
                })
              }
            >
              <Download />
              <Trans>Export</Trans>
            </Button>
          </Col>
        </AccessControl>
      </Row>
    </BlockHead>
  );
}

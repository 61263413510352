import { createEffect, createStore, combine, sample } from 'effector';

import { createFetching } from '@lib/effector-utils';
import { getFullName } from '@lib/help-fns';
import {
  fetchEmployeeList,
  fetchDepartmentList,
  fetchMeetingList,
  fetchProjectList,
  fetchOfficeList,
} from '../api';

// Events
export const employeeListFetchRequesting = createEffect({
  name: 'employee list fetch requested',
  handler: fetchEmployeeList,
});
export const departmentListFetchRequesting = createEffect({
  name: 'department list fetch requested',
  handler: fetchDepartmentList,
});
export const meetingListFetchRequesting = createEffect({
  name: 'meeting list fetch requested',
  handler: fetchMeetingList,
});
export const projectListFetchRequesting = createEffect({
  name: 'project list fetch requested',
  handler: fetchProjectList,
});
export const officeListFetchRequesting = createEffect({
  name: 'office list fetch requested',
  handler: fetchOfficeList,
});

export const basicDataFetchRequesting = createEffect({
  name: 'basic data fetch requested',
  handler: () =>
    Promise.all([
      employeeListFetchRequesting({ status: 'all' }),
      departmentListFetchRequesting(),
    ]),
});

// Stores
export const $allEmployeesList = createStore([]); // all employees including terminated
export const $employeeList = $allEmployeesList.map(list =>
  list.filter(item => item.status !== 'terminate'),
); // only active employees
export const $employeeListFetching = createFetching(
  employeeListFetchRequesting,
);
export const $departmentList = createStore([]);
export const $meetingList = createStore([]);
export const $projectList = createStore([]);
export const $officeList = createStore([]);

export const $projectListFetching = createFetching(projectListFetchRequesting);
export const $basicDataFetching = createFetching(basicDataFetchRequesting);

export const $employeeByDepartmentList = combine(
  $departmentList,
  $employeeList,
  (departments, employees) =>
    departments.reduce(
      (acc, item) =>
        acc.concat([
          {
            ...item,
            employees: employees.filter(
              ({ department }) => department.id === item.id,
            ),
          },
        ]),
      [],
    ),
);

// Reducers
$allEmployeesList.on(
  employeeListFetchRequesting.done.map(mapResultData),
  (_, list) => list.map(item => ({ ...item, fullName: getFullName(item) })),
);

$departmentList.on(
  departmentListFetchRequesting.done.map(mapResultData),
  (_, list) => list,
);

$meetingList.on(
  meetingListFetchRequesting.done.map(mapResultData),
  (_, list) => list,
);

$projectList.on(
  projectListFetchRequesting.done.map(mapResultData),
  (_, list) => list,
);

$officeList.on(
  officeListFetchRequesting.done.map(mapResultData),
  (_, list) => list,
);

function mapResultData({ result }) {
  return result ? result.data : result;
}

import {
  createEvent,
  createStore,
  createEffect,
  sample,
  guard,
  combine,
  merge,
} from 'effector';
import { createGate } from 'effector-react';
import { createFetching } from '@lib/effector-utils';

import {
  employeeListFetchRequesting,
  $allEmployeesList as $employeeList,
  $session,
} from '@features/common';
import { notify } from '@lib/notifier';
import { history } from '@lib/routing';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { transformEmployee } from '../lib/helpers';
import {
  changePassword,
  fetchSingleEmployee,
  terminateEmployee,
  fetchEmployeeLeaves,
  adjustEmployeeLeave,
} from '../api';

// GATES
export const ProfileGate = createGate('employee profile gate');

// EVENTS
export const openChangePasswordForm = createEvent(
  'change password form opened',
);
export const closeChangePasswordForm = createEvent(
  'change password form closed',
);
export const leaveAdjustSubmitClicked = createEvent(
  'leave adjust submit clicked',
);
export const openLeaveAdjustDialog = createEvent('leave adjust dialog opened');
export const closeLeaveAdjustDialog = createEvent('leave adjust dialog closed');

export const fetchEmployeeProfileRequesting = createEffect({
  handler: fetchSingleEmployee,
});
export const fetchEmployeeLeavesRequesting = createEffect({
  handler: fetchEmployeeLeaves,
});
export const changePasswordRequesting = createEffect({
  handler: changePassword,
});
export const terminateEmployeeRequesting = createEffect({
  handler: terminateEmployee,
});
export const adjustLeaveRequesting = createEffect({
  handler: adjustEmployeeLeave,
});

// STORES
export const $profile = combine(
  { list: $employeeList, profile: ProfileGate.state },
  ({ list, profile: { username } }) => {
    if (username) {
      const user = list.find(item => item.username === username);

      return user ? transformEmployee(user) : null;
    }
    return null;
  },
);
export const $isChangePasswordFormOpened = createStore(false);
export const $passwordChangeFetching = createFetching(
  changePasswordRequesting,
  {
    reset: closeChangePasswordForm,
  },
);
export const $leaveAdjustDialogId = createStore(null);
export const $leaveAdjustFetching = createFetching(adjustLeaveRequesting, {
  reset: adjustLeaveRequesting.done,
});

// REDUCERS
$profile.on(fetchEmployeeLeavesRequesting.done, (profile, { result }) => ({
  ...profile,
  leaves: result,
}));

$isChangePasswordFormOpened
  .on(openChangePasswordForm, () => true)
  .on(closeChangePasswordForm, () => false);

$leaveAdjustDialogId
  .on(openLeaveAdjustDialog, (_, id) => id)
  .on(closeLeaveAdjustDialog, () => null);

// SIDE EFFECTS
sample($profile, terminateEmployeeRequesting.done).watch(
  ({ fullName: name }) => {
    employeeListFetchRequesting();
    notify.success(i18n._(t`${name} is successfully terminated!`));
    history.replace('/hr/employees');
  },
);

changePasswordRequesting.done.watch(() => {
  closeChangePasswordForm();
  notify.success(i18n._(t`You have successfully changed your password!`));
});

sample(
  $profile,
  terminateEmployeeRequesting.fail,
  ({ fullName }, { error }) => ({ name: fullName, error }),
).watch(({ name, error }) =>
  notify.error(i18n._(t`Unable to terminate ${name}. Reason: ${error}`)),
);

sample({
  source: leaveAdjustSubmitClicked,
  fn: ({ adjustment, ...rest }) => ({
    ...rest,
    adjustment: Number(adjustment),
  }),
  target: adjustLeaveRequesting,
});

const onAdjustmentSuccess = sample({
  source: $profile,
  clock: adjustLeaveRequesting.done,
  fn: (profile, { params }) => {
    const leaveType = profile.leaves.types.find(
      ({ id }) => id === params.leaveType,
    );

    return {
      name: leaveType ? leaveType.name : '',
      user: profile.fullName,
    };
  },
});

// Fetch employee's leave data if viewer has permissions for that
guard({
  source: sample({
    source: combine({ viewer: $session, profile: $profile }),
    clock: merge([ProfileGate.state.updates, onAdjustmentSuccess]),
  }),
  filter: ({ viewer, profile }) => {
    if (viewer && profile) {
      return (
        viewer.topLevelPermission ||
        (viewer.position === 'manager' &&
          viewer.department.name === profile.department.name) ||
        profile.id === viewer.id
      );
    }
    return false;
  },
  target: fetchEmployeeLeavesRequesting.prepend(({ profile }) => ({
    id: profile.id,
  })),
});

sample({
  source: adjustLeaveRequesting.done,
  target: closeLeaveAdjustDialog,
});

onAdjustmentSuccess.watch(({ name, user }) => {
  notify.success(i18n._(t`Leaves: ${name} leave adjusted for ${user}!`));
});

adjustLeaveRequesting.fail.watch(({ error }) => {
  console.log(error);
  if (error.data) {
    notify.error(i18n._(t`Leaves: ${error.data}`));
  }
});
